.social-bar {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 100;
    cursor: pointer;

    .icon {
        color: rgb(0, 0, 0);
        text-decoration: none;
        display: flex;
        transition: all .5s;
        background-color: rgb(255, 255, 255);
    }

    .icon-up:hover {
        color: rgb(0, 0, 0);
    }
    .icon-whatsapp:hover {
        color: #25D336;
    }
    .icon-shop:hover {
        color:  #ebb916;
    }
    .icon-instagram:hover {
        color: #DD2A7B;
    }
    .icon-youtube:hover {
        color:  #c4302b;
    }
}
// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .ImagesSlider {
        width: 450px;
        height: 665px;
        border-radius: 1em;
        left: 0;
        width: 30vw !important;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                width: 100%;
                top: 0;
                left: 0;
                height: 100%;
                object-position: 50% 50%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .ImagesSlider {
        width: 450px;
        height: 665px;
        border-radius: 1em;
        left: 0;
        width: 30vw !important;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                width: 110%;
                top: 0;
                left: 0;
                height: 100%;
                object-position: 50% 50%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .ImagesSlider {
        height: 400px;
        border-radius: 1em;
        left: 0;
        width: 66vw !important;
        margin-top: 3em;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                width: 110%;
                top: 0;
                left: 0;
                height: 100%;
                object-position: 50% 50%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .ImagesSlider {
        height: 400px;
        border-radius: 1em;
        left: 0;
        width: 55vw !important;
        margin-top: 3em;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                width: 110%;
                top: 0;
                left: 0;
                height: 100%;
                object-position: 50% 90%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .ImagesSlider {
        height: 400px;
        border-radius: 1em;
        left: 0;
        width: 69vw !important;
        margin-top: 3em;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                width: 110%;
                top: 0;
                left: 0;
                height: 100%;
                object-position: 50% 90%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .ImagesSlider {
        height: 400px;
        border-radius: 1em;
        left: 0;
        width: 69vw !important;
        margin-top: 3em;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                width: 110%;
                top: 0;
                left: 0;
                height: 100%;
                object-position: 50% 90%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}
// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .VideoOceanContainer {
        height: 50vh;
        max-width: 100vw !important;
        padding: 0 !important;
        text-align: right;
        .VideoText {
            margin-right: 3em;
            padding-left: 3em;
            padding-right: 3em;
            height: 70%;
            border: 1px solid white;
            width: 50vw;
            .OceanTitle {
                font-size: 1.5em;
            }
        }
        .VideoOcean {
            display: none;
        }
        .Carousel__heroImage {
            height: 50vh;
            border-radius: 0;
            width: 50vw !important;
            object-fit: cover;
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .VideoOceanContainer {
        height: 50vh;
        max-width: 100vw !important;
        padding: 0 !important;
        text-align: right;
        .VideoText {
            margin-right: 2em;
            padding-left: 2em;
            padding-right: 2em;
            height: 80%;
            border: 1px solid white;
            font-size: .8em;
            .OceanTitle {
                font-size: 1.5em;
            }
        }
        .VideoOcean {
            display: none;
        }
        .Carousel__heroImage {
            height: 50vh;
            border-radius: 0;
            width: 50vw !important;
            object-fit: cover;
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .VideoOceanContainer {
        height: 50vh;
        max-width: 100vw !important;
        padding: 0 !important;
        overflow: hidden;
        .VideoText {
            display: none;
        }
        .VideoOcean {
            display: none;
        }
        .Carousel__heroImage {
            height: 50vh;
            border-radius: 0;
            width: 100vw !important;
            object-fit: cover;
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .VideoOceanContainer {
        height: 50vh;
        max-width: 100vw !important;
        padding: 0 !important;
        overflow: hidden;
        .VideoText {
            display: none;
        }
        .VideoOcean {
            display: none;
        }
        .Carousel__heroImage {
            height: 50vh;
            border-radius: 0;
            width: 100vw !important;
            object-fit: cover;
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .VideoOceanContainer {
        height: 40vh;
        max-width: 100vw !important;
        padding: 0 !important;
        overflow: hidden;
        .VideoText {
            display: none;
        }
        .VideoOcean {
            display: none;
        }
        .Carousel__heroImage {
            height: 40vh;
            border-radius: 0;
            width: 100vw !important;
            object-fit: cover;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .VideoOceanContainer {
        height: 40vh;
        max-width: 100vw !important;
        padding: 0 !important;
        overflow: hidden;
        .VideoText {
            display: none;
        }
        .VideoOcean {
            display: none;
        }
        .Carousel__heroImage {
            height: 40vh;
            border-radius: 0;
            width: 100vw !important;
            object-fit: cover;
        }
    }
}
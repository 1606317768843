.VideoOceanContainer {
    display: flex;
    align-items: center;
    background-color: #569695;
    color: white;
    .VideoText {
        background: radial-gradient(56.56% 100% at 50% 0%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        .OceanTitle {
            font-family: BadScript;
        }
    }
}
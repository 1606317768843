.Footer {
    background: linear-gradient(#4684B8, #030203 );
    color: white;
    font-weight: bold;
    --bs-gutter-x: 0rem !important;
    display: flex;
    align-items: center;
    .FooterText {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        .FooterLeft {
            text-align: left;
        }
        .Title {
            font-family: amalfi;
            text-transform: capitalize;
            color: white;
        }
    }
    .FooterNavbar {
        ul {
            list-style: none;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            li {
                text-transform: uppercase;
                .NavBarLink {
                    position: relative;
                    font-family: Assistant;
                    font-weight: 100;
                    color: white;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: all 0.3s;
                    cursor: pointer;
                }
                .NavBarLink:hover {
                    border-bottom: 1px solid white;
                }
            }
        }
    }
}
.DetailsTitle {
    color: white;
    font-family: Amalfi;
}
.Line {
    color: white;
    width: 15%;
}

.SliderContainer {
    background: linear-gradient(180deg, #030203 0%, #4684B8 100%);
    width: 100vw;
    --bs-gutter-x: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Slider {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.CardItem__container {
    position: relative;

    .CardItem__content {
        .CardItem__image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            border: 1px solid white;
            border-radius: 2em;
        }
        // IMAGES
        .detail1 {
            background-image: url('../../../Assets/DetailsImages/detail1.jpg');
            background-position: 10% 50%;
        }
        .detail2 {
            background-image: url('../../../Assets/DetailsImages/detail2.jpg');
        }
        .detail3 {
            background-image: url('../../../Assets/DetailsImages/detail3.jpg');
        }
        .detail4 {
            background-image: url('../../../Assets/DetailsImages/detail4.jpg');
        }
        .detail5 {
            background-image: url('../../../Assets/DetailsImages/detail5.jpg');
            background-position: 30% 50%;

        }
        .detail6 {
            background-image: url('../../../Assets/DetailsImages/detail6.jpg');
            background-position: 100% 50%;
        }
        .detail7 {
            background-image: url('../../../Assets/DetailsImages/detail7.jpg');
        }
        .detail8 {
            background-image: url('../../../Assets/DetailsImages/detail8.jpg');
            background-position: 20% 50%;
        }

        .CardItem__description {
            // visibility: visible;
            color: white;
            text-align: center;
            -moz-transition: all 0.2s ease-out 0s;
            transition: all 0.2s ease-out 0s;
            display: flex;
            border-top: 1px solid white;
            background: radial-gradient(10.01% 94.45% at 50% 0.38%, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.6) 100%);

            p {
                font-family: BadScript;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }

        // HOVER
        // .overlay:hover{
        //     -webkit-filter: brightness(60%);
        //     filter:brightness(60%);
        //     visibility: visible;
        // }
        // .CardItem__description:hover {
        //     visibility: visible;
        //     opacity: 1;
        // }
    }
}
// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .GalleryImgContainer {
        background: linear-gradient(#030203, #4684B8, #030203 );

        .PortfolioTitle{
            padding-top: 3em;
        }

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 17px;
            margin-top: 7em;
            padding: 1em;
            height:4000px;
            padding-left: 8em;
            padding-right: 8em;
            width: 100vw;

            a {
                .GridItem {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: 1px solid white;
                    border-radius: 1em;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .One {
                    object-position: 50% 30%;
                }
                .Two {
                    object-position: 50% 60%;
                }
                .Five {
                    object-position: 50% 60%;
                }
                .Six {
                    object-position: 50% 65%;
                }
                .Seven {
                    object-position: 50% 80%;
                }
                .Eight {
                    object-position: 50% 70%;
                }
                .Twelve {
                    object-position: 50% 65%;
                }
                .Thirteen {
                    object-position: 50% 88%;
                }
            }
            .CardItemDescription {
                height: 3em;
                bottom: 0%;
                width: 100%;
                border-radius: 0 0 1em 1em;
                border-top: 1px solid white;
                p {
                    font-size: 1.3em;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .GalleryImgContainer {
        background: linear-gradient(#030203, #4684B8, #030203 );

        .PortfolioTitle{
            padding-top: 3em;
        }

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            margin-top: 7em;
            padding: 1em;
            height:2500px;
            padding-left: 8em;
            padding-right: 8em;
            width: 100vw;

            a {
                .GridItem {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: 1px solid white;
                    border-radius: 1em;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .One {
                    object-position: 50% 75% !important;
                }
                .Two {
                    object-position: 50% 60%;
                }
                .Five {
                    object-position: 50% 60%;
                }
                .Six {
                    object-position: 50% 65%;
                }
                .Seven {
                    object-position: 50% 80%;
                }
                .Eight {
                    object-position: 50% 70%;
                }
                .Twelve {
                    object-position: 50% 65%;
                }
                .Thirteen {
                    object-position: 50% 88%;
                }
            }
            .CardItemDescription {
                height: 3em;
                bottom: 0%;
                width: 100%;
                border-radius: 0 0 1em 1em;
                border-top: 1px solid white;
                p {
                    font-size: 1em;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .GalleryImgContainer {
        background: linear-gradient(#030203, #4684B8, #030203 );

        .PortfolioTitle{
            padding-top: 3em;
        }

        .GalleryWrapper {
            grid-template-columns: repeat(minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            margin-top: 7em;
            padding: 1em;
            height:2000px;
            padding-left: 8em;
            padding-right: 8em;
            width: 100vw;

            a {
                .GridItem {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: 1px solid white;
                    border-radius: 1em;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .One {
                    object-position: 50% 75% !important;
                }
                .Two {
                    object-position: 50% 60%;
                }
                .Five {
                    object-position: 50% 60%;
                }
                .Six {
                    object-position: 50% 65%;
                }
                .Seven {
                    object-position: 50% 80%;
                }
                .Eight {
                    object-position: 50% 70%;
                }
                .Twelve {
                    object-position: 50% 65%;
                }
                .Thirteen {
                    object-position: 50% 88%;
                }
            }
            .CardItemDescription {
                height: 3em;
                bottom: 0%;
                width: 100%;
                border-radius: 0 0 1em 1em;
                border-top: 1px solid white;
                p {
                    font-size: 1em;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .GalleryImgContainer {
        background: linear-gradient(#030203, #4684B8, #030203 );

        .PortfolioTitle{
            padding-top: 3em;
        }

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 17px;
            margin-top: 5em;
            padding: 1em;
            height: 7000px;
            padding-left: 8em;
            padding-right: 8em;
            width: 100vw;

            a {
                .GridItem {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: 1px solid white;
                    border-radius: 1em;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .One {
                    object-position: 50% 75% !important;
                }
                .Two {
                    object-position: 50% 60%;
                }
                .Five {
                    object-position: 50% 60%;
                }
                .Six {
                    object-position: 50% 65%;
                }
                .Seven {
                    object-position: 50% 80%;
                }
                .Eight {
                    object-position: 50% 70%;
                }
                .Twelve {
                    object-position: 50% 65%;
                }
                .Thirteen {
                    object-position: 50% 88%;
                }
            }
            .CardItemDescription {
                height: 3em;
                bottom: 0%;
                width: 100%;
                border-radius: 0 0 1em 1em;
                border-top: 1px solid white;
                p {
                    font-size: 1em;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .GalleryImgContainer {
        background: linear-gradient(#030203, #4684B8, #030203 );
        padding-top: 1em;

        .PortfolioTitle{
            margin-top: 3em;
            font-size: 1.2em;
        }

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 17px;
            margin-top: 3em;
            margin-bottom: 1em;
            padding: 1em;
            height: 7000px;
            padding-left: 8em;
            padding-right: 8em;
            width: 100vw;

            a {
                .GridItem {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: 1px solid white;
                    border-radius: 1em;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .One {
                    object-position: 50% 75% !important;
                }
                .Two {
                    object-position: 50% 60%;
                }
                .Five {
                    object-position: 50% 60%;
                }
                .Six {
                    object-position: 50% 65%;
                }
                .Seven {
                    object-position: 50% 80%;
                }
                .Eight {
                    object-position: 50% 70%;
                }
                .Twelve {
                    object-position: 50% 65%;
                }
                .Thirteen {
                    object-position: 50% 88%;
                }
            }
            .CardItemDescription {
                height: 3em;
                bottom: 0%;
                width: 100%;
                border-radius: 0 0 1em 1em;
                border-top: 1px solid white;
                p {
                    font-size: 1em;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }
        .gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
            margin: 0 auto;
            max-height: 70vh;
            width: 301px;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .GalleryImgContainer {
        background: linear-gradient(#030203, #4684B8, #030203 );
        padding-top: 2em;

        .PortfolioTitle{
            margin-top: 3em;
            font-size: 1.2em;
        }

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 17px;
            margin-top: 3em;
            margin-bottom: 1em;
            padding: 1em;
            height: 7000px;
            padding-left: 8em;
            padding-right: 8em;
            width: 100vw;

            a {
                .GridItem {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: 1px solid white;
                    border-radius: 1em;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .One {
                    object-position: 50% 75% !important;
                }
                .Two {
                    object-position: 50% 60%;
                }
                .Five {
                    object-position: 50% 60%;
                }
                .Six {
                    object-position: 50% 65%;
                }
                .Seven {
                    object-position: 50% 80%;
                }
                .Eight {
                    object-position: 50% 70%;
                }
                .Twelve {
                    object-position: 50% 65%;
                }
                .Thirteen {
                    object-position: 50% 88%;
                }
            }
            .CardItemDescription {
                height: 3em;
                bottom: 0%;
                width: 100%;
                border-radius: 0 0 1em 1em;
                border-top: 1px solid white;
                p {
                    font-size: 1em;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }
        .gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
            margin: 0 auto;
            max-height: 70vh;
            width: 300px;
        }
    }
}
// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .Footer {
        height: 12em;
        .FooterText {
            .Title {
                font-size:2em;
                font-weight: 200;
            }
            .CopyrightSmallDevices {
                display: none;
            }
        }
        .FooterNavbar {
            --bs-gutter-x: 0rem !important;
            ul {
                padding-left: 7em;
                padding-right: 7em;
                li {
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.8em;
                margin-bottom: 0;
                font-weight: 200;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .Footer {
        height: 12em;
        .FooterText {
            .Title {
                font-size:2em;
                font-weight: 200;
            }
            .CopyrightSmallDevices {
                display: none;
            }
        }
        .FooterNavbar {
            --bs-gutter-x: 0rem !important;
            ul {
                padding-left: 4em;
                padding-right: 4em;
                li {
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.8em;
                margin-bottom: 0;
                font-weight: 200;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .Footer {
        height: 12em;
        .FooterText {
            .Title {
                font-size:2em;
                font-weight: 200;
            }
            .CopyrightSmallDevices {
                display: none;
            }
        }
        .FooterNavbar {
            --bs-gutter-x: 0rem !important;
            ul {
                padding-left: 4em;
                padding-right: 4em;
                li {
                    .NavBarLink {
                        font-size: .8em;
                    }
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: .8em;
                margin-bottom: 0;
                font-weight: 200;
                .rm {
                    color: white;
                    font-weight: 200;
                    text-decoration: none;
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .Footer {
        height: 12em;
        .FooterText {
            .Title {
                font-size:2em;
                font-weight: 100;
            }
            .CopyrightSmallDevices {
                text-align: center;
                font-size: .8em;
                margin-top: 2em;
                font-weight: 100;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
        .FooterNavbar {
            display: none;
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .Footer {
        height: 12em;
        .FooterText {
            .Title {
                font-size:2em;
                font-weight: 100;
            }
            .CopyrightSmallDevices {
                text-align: center;
                font-size: .8em;
                margin-top: 2em;
                font-weight: 100;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
        .FooterNavbar {
            display: none;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .Footer {
        height: 12em;
        .FooterText {
            .Title {
                font-size:2em;
                font-weight: 100;
            }
            .CopyrightSmallDevices {
                text-align: center;
                font-size: .8em;
                margin-top: 2em;
                font-weight: 100;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
        .FooterNavbar {
            display: none;
        }
    }
}
// X-LARGE DEVICES [XL]
@media all and (min-width: 1199px) {
    .SliderContainer {
        height: 100vh;
    }

    .Slider {
        margin-top: 1%;
        width: 80vw;
    }

    .CardItem__container {
        width: 288px !important;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                display: flex;
                align-items: flex-end;
                height: 442px;
                width: 80%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                width: 100%;
                height: 4em;
                color: rgb(0, 0, 0);
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 65vh;
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1150px) {
    .SliderContainer {
        height: 100vh;
    }

    .Slider {
        margin-top: 1%;
        width: 80vw;
    }

    .CardItem__container {
        width: 288px !important;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                display: flex;
                align-items: flex-end;
                height: 442px;
                width: 85%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                width: 100%;
                height: 4em;
                color: rgb(0, 0, 0);
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 65vh;
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .SliderContainer {
        height: 100vh;
    }

    .Slider {
        margin-top: 1%;
        width: 80vw;
    }

    .CardItem__container {
        width: 288px !important;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                display: flex;
                align-items: flex-end;
                height: 442px;
                width: 65%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                width: 100%;
                height: 4em;
                color: rgb(0, 0, 0);
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 65vh;
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .SliderContainer {
        height: 100vh;
    }

    .Slider {
        margin-top: 1%;
        width: 80vw;
    }

    .CardItem__container {
        width: 288px !important;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                display: flex;
                align-items: flex-end;
                height: 442px;
                width: 100%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                width: 100%;
                height: 4em;
                color: rgb(0, 0, 0);
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 65vh;
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .SliderContainer {
        height: 100vh;
    }

    .Slider {
        margin-top: 1%;
        width: 80vw;
    }

    .CardItem__container {
        width: 288px !important;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                display: flex;
                align-items: flex-end;
                height: 442px;
                width: 100%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                width: 100%;
                height: 4em;
                color: rgb(0, 0, 0);
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 65vh;
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .SliderContainer {
        height: 100vh;
    }

    .Slider {
        margin-top: 1%;
        width: 80vw;
    }

    .CardItem__container {
        width: 288px !important;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                display: flex;
                align-items: flex-end;
                height: 442px;
                width: 100%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                width: 100%;
                height: 4em;
                color: rgb(0, 0, 0);
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 65vh;
    }
}
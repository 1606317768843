.AboutMeContainer {
    background: linear-gradient(#030203, #4684B8, #030203 );
    .AboutMeRow {
        align-items: center;
        .AboutMeText {
            .AboutMeTextContent {
                color: white;
                h3 {
                    font-family: Amalfi;
                }
                .LineContainer {
                    display: flex;
                    justify-content: center;
                    .Line {
                        display: flex;
                        justify-content: center;
                    }
                }
                .LargeDevices {
                    font-family: Assistant;
                    text-align: justify;
                    overflow-y: auto;
                }

                .Info {
                    list-style: none;
                    li a {
                        text-decoration: none;
                        color: white;
                    }
                }
                .ButtonsCV {
                    .buttonCV {
                        background: #4479a4;
                        color: white;
                    }
                    .English {
                        margin-bottom: 0.5em;
                    }
                    .buttonCV:hover {
                        background: radial-gradient(rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.6) 100%);
                    }
                }
            }
        }
        .FadeSliderContainer {
            padding: 0 !important;
            --bs-gutter-x: 0 !important;
        }
    }

    .SkillsRow {
        .Skill {
            p {
                color: white !important;
                font-family: BadScript;
            }
            .LineContainer {
                display: flex;
                justify-content: center;
            }
            .ImgSkillContainer {
                display: flex;
                justify-content: center;
                .ImgOil {
                    background-image: url('../../../Assets/SkillsImages/oleo.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .ImgOil:hover {
                    background-image: url('../../../Assets/DetailsImages/detail1.jpg');
                }
                .ImgAcrylic {
                    background-image: url('../../../Assets/SkillsImages/acrylic.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .ImgAcrylic:hover {
                    background-image: url('../../../Assets/SkillsImages/zoomAcrylic.png');
                }
                .ImgGlass {
                    background-image: url('../../../Assets/SkillsImages/Glass.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .ImgGlass:hover {
                    background-image: url('../../../Assets/SkillsImages/zoomGlass.jpg');
                }
                .ImgPencil {
                    background-image: url('../../../Assets/SkillsImages/pencil.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .ImgPencil:hover {
                    background-image: url('../../../Assets/SkillsImages/zoomPencil.png');
                }
            }
        }
    }
}
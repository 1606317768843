// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .NavBarContainer {
        height: 7em;
        padding-top: 2em;
        width: 100vw;
        font-size: .9em;
        .NavBarRow {
            --bs-gutter-x: 0 !important;
            width: 100%;
            .NavBarLogo {
                padding-left: 8em;
                font-weight: 400;
                ul {
                    padding: 0 !important;
                }
            }
            .NavBarLinks {
                padding-right: 8em;
                font-weight: 400;
                ul {
                    padding: 0 !important;
                    width: 80%;
                }
            }
            .BurgerMenuLabel {
                display: none;
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .NavBarContainer {
        height: 7em;
        padding-top: 2em;
        width: 100vw;
        font-size: .9em;
        .NavBarRow {
            --bs-gutter-x: 0 !important;
            max-width: 100vw;
            .NavBarLogo {
                padding-left: 8em;
                font-weight: 400;
                ul {
                    padding: 0 !important;
                }
            }
            .NavBarLinks {
                padding-right: 8em;
                font-weight: 400;
                ul {
                    padding: 0 !important;
                    width: 80%;
                }
            }
            .BurgerMenuLabel {
                display: none;
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .NavBarContainer {
        height: 7em;
        padding-top: 2em;
        width: 100vw;
        font-size: .9em;
        .NavBarRow {
            --bs-gutter-x: 0 !important;
            max-width: 100vw;
            .NavBarLogo {
                display: none;
            }
            .NavBarLinks {
                display: none;
            }
            .BurgerMenuLabel {
                margin-right: 5em;
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .NavBarContainer {
        height: 7em;
        padding-top: 2em;
        width: 100vw;
        font-size: .9em;
        .NavBarRow {
            --bs-gutter-x: 0 !important;
            max-width: 100vw;
            .NavBarLogo {
                display: none;
            }
            .NavBarLinks {
                display: none;
            }
            .BurgerMenuLabel {
                margin-right: 5em;
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .NavBarContainer {
        height: 7em;
        padding-top: 2em;
        width: 100vw;
        font-size: .9em;
        .NavBarRow {
            --bs-gutter-x: 0 !important;
            max-width: 100vw;
            .NavBarLogo {
                display: none;
            }
            .NavBarLinks {
                display: none;
            }
            .BurgerMenuLabel {
                margin-right: 5em;
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .NavBarContainer {
        height: 7em;
        padding-top: 2em;
        width: 100vw;
        font-size: .9em;
        .NavBarRow {
            --bs-gutter-x: 0 !important;
            max-width: 100vw;
            .NavBarLogo {
                display: none;
            }
            .NavBarLinks {
                display: none;
            }
            .BurgerMenuLabel {
                margin-right: 5em;
            }
        }
    }
}
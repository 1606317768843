// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .AboutMeContainer {
        height: auto;
        width: 100vw;
        padding-top: 3em;
        .AboutMeRow {
            display: flex;
            height: 100vh;
            padding-right: 8em;
            padding-left: 8em;
            .AboutMeText {
                .AboutMeTextContent {
                    width: 100%;
                    h3 {
                        width: 100%;
                    }
                    .LineContainer {
                        width: 100%;
                        margin-top: 2em;
                        margin-bottom: 1.5em;
                        .Line {
                            width: 8em;
                        }
                    }
                    .LargeDevicesContainer {
                        .LargeDevices {
                            width: 100%;
                            height: 40vh;
                            margin-bottom: 2em;
                            padding-right: 1em;
                        }
                        .LargeDevices::-webkit-scrollbar {
                            width: 1px;
                            background-color: rgb(157, 154, 146);
                        }
                        .LargeDevices::-webkit-scrollbar-thumb {
                            background-color: white;
                            width: 2px;
                        }
                    }
                    .Info {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 2em;
                    }
                    .ButtonsCV {
                        .buttonCV {
                            border: 1px solid #FFFFFF;
                            border-radius: 5px;
                            width: 100%;
                            height: 2.5em;
                        }
                        .buttonCV:hover {
                            border: 1.5px solid #FFFFFF;
                        }
                    }
                }
            }
        }

        .SkillsRow {
            margin-top: 2em;
            margin-right: 7em;
            margin-left: 7em;
            .Skill {
                p {
                    font-size: 1.2em;
                }
                .LineContainer {
                    margin-bottom: 1em;
                    .LineSkill {
                        width: 100%;
                        color: white;
                    }
                }
                .ImgSkillContainer {
                    .ImgOil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                    }
                    .ImgAcrylic {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        background-position: 50% 100%;
                    }
                    .ImgGlass {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                    }
                    .ImgPencil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        transition: transform 0.25s;
                    }
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .AboutMeContainer {
        padding-bottom: 5em;
        height: auto;
        width: 100vw;
        padding-top: 3em;
        .AboutMeRow {
            display: flex;
            height: 100vh;
            padding-right: 8em;
            padding-left: 8em;
            .AboutMeText {
                .AboutMeTextContent {
                    width: 100%;
                    h3 {
                        width: 100%;
                    }
                    .LineContainer {
                        width: 100%;
                        margin-top: 2em;
                        margin-bottom: 1.5em;
                        .Line {
                            width: 8em;
                        }
                    }
                    .LargeDevicesContainer {
                        .LargeDevices {
                            width: 100%;
                            height: 40vh;
                            margin-bottom: 2em;
                            padding-right: 1em;
                        }
                        .LargeDevices::-webkit-scrollbar {
                            width: 1px;
                            background-color: rgb(157, 154, 146);
                        }
                        .LargeDevices::-webkit-scrollbar-thumb {
                            background-color: white;
                            width: 2px;
                        }
                    }
                    .Info {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 2em;
                    }
                    .ButtonsCV {
                        .buttonCV {
                            border: 1px solid #FFFFFF;
                            border-radius: 5px;
                            width: 100%;
                            height: 2.5em;
                        }
                        .buttonCV:hover {
                            border: 1.5px solid #FFFFFF;
                        }
                    }
                }
            }
        }

        .SkillsRow {
            margin-top: 2em;
            margin-right: 7em;
            margin-left: 7em;
            .Skill {
                p {
                    font-size: 1.2em;
                }
                .LineContainer {
                    margin-bottom: 1em;
                    .LineSkill {
                        width: 100%;
                        color: white;
                    }
                }
                .ImgSkillContainer {
                    .ImgOil {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                    }
                    .ImgAcrylic {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                        background-position: 50% 100%;
                    }
                    .ImgGlass {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                    }
                    .ImgPencil {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                        transition: transform 0.25s;
                    }
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .AboutMeContainer {
        padding-bottom: 5em;
        height: auto;
        width: 100vw;
        padding-top: 3em;
        .AboutMeRow {
            display: inline-block;
            width: 100vw;
            height: auto;
            padding-right: 8em;
            padding-left: 8em;
            .AboutMeText {
                margin-top: 3em;
                width: 100%;
                padding: 0 !important;
                .AboutMeTextContent {
                    width: 100%;
                    h3 {
                        width: 100%;
                    }
                    .LineContainer {
                        width: 100%;
                        margin-top: 2em;
                        margin-bottom: 1.5em;
                        .Line {
                            width: 8em;
                        }
                    }
                    .LargeDevicesContainer {
                        .LargeDevices {
                            width: 100%;
                            height: 40vh;
                            margin-bottom: 2em;
                            padding-right: 1em;
                        }
                        .LargeDevices::-webkit-scrollbar {
                            width: 1px;
                            background-color: rgb(157, 154, 146);
                        }
                        .LargeDevices::-webkit-scrollbar-thumb {
                            background-color: white;
                            width: 2px;
                        }
                    }
                    .Info {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 2em;
                    }
                    .ButtonsCV {
                        .buttonCV {
                            border: 1px solid #FFFFFF;
                            border-radius: 5px;
                            width: 100%;
                            height: 2.5em;
                        }
                        .buttonCV:hover {
                            border: 1.5px solid #FFFFFF;
                        }
                    }
                }
            }
        }

        .SkillsRow {
            margin-top: 2em;
            margin-right: 7em;
            margin-left: 7em;
            .Skill {
                p {
                    font-size: 1.2em;
                }
                .LineContainer {
                    margin-bottom: 1em;
                    .LineSkill {
                        width: 100%;
                        color: white;
                    }
                }
                .ImgSkillContainer {
                    .ImgOil {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgAcrylic {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                        background-position: 50% 100%;
                    }
                    .ImgGlass {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                    }
                    .ImgPencil {
                        border-radius: 50%;
                        width: 150px;
                        height: 150px;
                        border: 1px solid white;
                        transition: transform 0.25s;
                    }
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .AboutMeContainer {
        padding-bottom: 5em;
        height: auto;
        width: 100vw;
        padding-top: 3em;
        .AboutMeRow {
            display: inline-block;
            width: 100vw;
            height: auto;
            padding-right: 8em;
            padding-left: 8em;
            .AboutMeText {
                margin-top: 3em;
                width: 100%;
                padding: 0 !important;
                .AboutMeTextContent {
                    width: 100%;
                    h3 {
                        width: 100%;
                    }
                    .LineContainer {
                        width: 100%;
                        margin-top: 2em;
                        margin-bottom: 1.5em;
                        .Line {
                            width: 8em;
                        }
                    }
                    .LargeDevicesContainer {
                        .LargeDevices {
                            width: 100%;
                            height: 40vh;
                            margin-bottom: 2em;
                            padding-right: 1em;
                        }
                        .LargeDevices::-webkit-scrollbar {
                            width: 1px;
                            background-color: rgb(157, 154, 146);
                        }
                        .LargeDevices::-webkit-scrollbar-thumb {
                            background-color: white;
                            width: 2px;
                        }
                    }
                    .Info {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 2em;
                    }
                    .ButtonsCV {
                        .buttonCV {
                            border: 1px solid #FFFFFF;
                            border-radius: 5px;
                            width: 100%;
                            height: 2em;
                        }
                        .buttonCV:hover {
                            border: 1.5px solid #FFFFFF;
                        }
                    }
                }
            }
        }

        .SkillsRow {
            margin-top: 2em;
            margin-right: 7em;
            margin-left: 7em;
            .Skill {
                p {
                    font-size: 1.2em;
                }
                .LineContainer {
                    margin-bottom: 1em;
                    .LineSkill {
                        width: 100%;
                        color: white;
                    }
                }
                .ImgSkillContainer {
                    .ImgOil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgAcrylic {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        background-position: 50% 100%;
                        margin-bottom: 2.5em;
                    }
                    .ImgGlass {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgPencil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        transition: transform 0.25s;
                        margin-bottom: .5em;
                    }
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .AboutMeContainer {
        padding-bottom: 3em;
        height: auto;
        width: 100vw;
        padding-top: 3em;
        .AboutMeRow {
            display: inline-block;
            width: 100vw;
            height: auto;
            padding-right: 4em;
            padding-left: 4em;
            .AboutMeText {
                margin-top: 3em;
                width: 100%;
                padding: 0 !important;
                .AboutMeTextContent {
                    width: 100%;
                    h3 {
                        width: 100%;
                    }
                    .LineContainer {
                        width: 100%;
                        margin-top: 2em;
                        margin-bottom: 1.5em;
                        .Line {
                            width: 8em;
                        }
                    }
                    .LargeDevicesContainer {
                        .LargeDevices {
                            width: 100%;
                            height: 40vh;
                            margin-bottom: 2em;
                            padding-right: 1em;
                        }
                        .LargeDevices::-webkit-scrollbar {
                            width: 1px;
                            background-color: rgb(157, 154, 146);
                        }
                        .LargeDevices::-webkit-scrollbar-thumb {
                            background-color: white;
                            width: 2px;
                        }
                    }
                    .Info {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 2em;
                    }
                    .ButtonsCV {
                        .buttonCV {
                            border: 1px solid #FFFFFF;
                            border-radius: 5px;
                            width: 100%;
                            height: 2em;
                        }
                        .buttonCV:hover {
                            border: 1.5px solid #FFFFFF;
                        }
                    }
                }
            }
        }

        .SkillsRow {
            margin-top: 3em;
            margin-right: 3em;
            margin-left: 3em;
            .Skill {
                p {
                    font-size: 1.2em;
                }
                .LineContainer {
                    margin-bottom: 1em;
                    .LineSkill {
                        width: 100%;
                        color: white;
                    }
                }
                .ImgSkillContainer {
                    .ImgOil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgAcrylic {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        background-position: 50% 100%;
                        margin-bottom: 2.5em;
                    }
                    .ImgGlass {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgPencil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        transition: transform 0.25s;
                        margin-bottom: 2.5em;
                    }
                }
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .AboutMeContainer {
        padding-bottom: 3em;
        height: auto;
        width: 100vw;
        padding-top: 3em;
        .AboutMeRow {
            display: inline-block;
            width: 100vw;
            height: auto;
            padding-right: 4em;
            padding-left: 4em;
            .AboutMeText {
                margin-top: 3em;
                width: 100%;
                padding: 0 !important;
                .AboutMeTextContent {
                    width: 100%;
                    h3 {
                        width: 100%;
                    }
                    .LineContainer {
                        width: 100%;
                        margin-top: 2em;
                        margin-bottom: 1.5em;
                        .Line {
                            width: 8em;
                        }
                    }
                    .LargeDevicesContainer {
                        .LargeDevices {
                            width: 100%;
                            height: 40vh;
                            margin-bottom: 2em;
                            padding-right: 1em;
                        }
                        .LargeDevices::-webkit-scrollbar {
                            width: 1px;
                            background-color: rgb(157, 154, 146);
                        }
                        .LargeDevices::-webkit-scrollbar-thumb {
                            background-color: white;
                            width: 2px;
                        }
                    }
                    .Info {
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 2em;
                    }
                    .ButtonsCV {
                        .buttonCV {
                            border: 1px solid #FFFFFF;
                            border-radius: 5px;
                            width: 100%;
                            height: 2em;
                        }
                        .buttonCV:hover {
                            border: 1.5px solid #FFFFFF;
                        }
                    }
                }
            }
        }

        .SkillsRow {
            margin-top: 3em;
            margin-right: 3em;
            margin-left: 3em;
            .Skill {
                p {
                    font-size: 1.2em;
                }
                .LineContainer {
                    margin-bottom: 1em;
                    .LineSkill {
                        width: 100%;
                        color: white;
                    }
                }
                .ImgSkillContainer {
                    .ImgOil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgAcrylic {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        background-position: 50% 100%;
                        margin-bottom: 2.5em;
                    }
                    .ImgGlass {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        margin-bottom: 2.5em;
                    }
                    .ImgPencil {
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        border: 1px solid white;
                        transition: transform 0.25s;
                        margin-bottom: 2.5em;
                    }
                }
            }
        }
    }
}
// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .HomeContainer {
        height: 120vh;
        width: 100vw;
        --bs-gutter-x:0 !important;
        .HeroImg {
            -webkit-filter: brightness(80%);
            filter:brightness(80%);
            z-index: -1;
            background-position: 0 80%;
            height: 120vh;
            width: 100vw;
        }
        .DivHome {
            .HomeNavBarRow {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
            .HomeRow {
                z-index: 2;
                width: 100vw;
                --bs-gutter-x:0 !important;
                .DivContent {
                    margin-top: 28%;
                    padding-left: 8em;
                    .Welcome {
                        font-weight: 400;
                        line-height: 5em;
                        font-size: 1.5em;
                    }
                    h1 {
                        font-size: 5em;
                    }
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .HomeContainer {
        height: 120vh;
        width: 100vw;
        --bs-gutter-x:0 !important;
        .HeroImg {
            -webkit-filter: brightness(80%);
            filter:brightness(80%);
            z-index: -1;
            background-position: 0 73%;
            height: 120vh;
            width: 100vw;
        }
        .DivHome {
            .HomeNavBarRow {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
            .HomeRow {
                z-index: 2;
                width: 100vw;
                --bs-gutter-x:0 !important;
                .DivContent {
                    margin-top: 35%;
                    padding-left: 8em;
                    .Welcome {
                        font-weight: 400;
                        line-height: 5em;
                        font-size: 1.5em;
                    }
                    h1 {
                        font-size: 5em;
                    }
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .HomeContainer {
        height: 130vh;
        width: 100vw;
        --bs-gutter-x:0 !important;
        .HeroImg {
            -webkit-filter: brightness(80%);
            filter:brightness(80%);
            z-index: -1;
            background-position: 0 73%;
            height: 130vh;
            width: 100vw;
        }
        .DivHome {
            .HomeNavBarRow {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
            .HomeRow {
                z-index: 2;
                width: 100vw;
                --bs-gutter-x:0 !important;
                .DivContent {
                    margin-top: 55%;
                    padding-left: 5em;
                    .Welcome {
                        font-weight: 400;
                        line-height: 5em;
                        font-size: 1.5em;
                    }
                    h1 {
                        font-size: 4em;
                    }
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .HomeContainer {
        height: 120vh;
        width: 100vw;
        --bs-gutter-x:0 !important;
        .HeroImg {
            -webkit-filter: brightness(80%);
            filter:brightness(80%);
            z-index: -1;
            background-position: 0 73%;
            height: 120vh;
            width: 100vw;
        }
        .DivHome {
            .HomeNavBarRow {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
            .HomeRow {
                z-index: 2;
                width: 100vw;
                --bs-gutter-x:0 !important;
                .DivContent {
                    margin-top: 70%;
                    padding-left: 5em;
                    .Welcome {
                        font-weight: 400;
                        line-height: 5em;
                        font-size: 1.2em;
                    }
                    h1 {
                        font-size: 3em;
                    }
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .HomeContainer {
        height: 100vh;
        width: 100vw;
        --bs-gutter-x:0 !important;
        .HeroImg {
            -webkit-filter: brightness(80%);
            filter:brightness(80%);
            z-index: -1;
            background-position: 60% 73%;
            height: 100vh;
            width: 100vw;
        }
        .DivHome {
            .HomeNavBarRow {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
            .HomeRow {
                z-index: 2;
                width: 100vw;
                --bs-gutter-x:0 !important;
                .DivContent {
                    margin-top: 62vh;
                    padding-left: 5em;
                    .Welcome {
                        font-weight: 400;
                        line-height: 3em;
                        font-size: 1em;
                    }
                    h1 {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .HomeContainer {
        height: 100vh;
        width: 100vw;
        --bs-gutter-x:0 !important;
        .HeroImg {
            -webkit-filter: brightness(80%);
            filter:brightness(80%);
            z-index: -1;
            background-position: 60% 73%;
            height: 100vh;
            width: 100vw;
        }
        .DivHome {
            .HomeNavBarRow {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
            .HomeRow {
                z-index: 2;
                width: 100vw;
                --bs-gutter-x:0 !important;
                .DivContent {
                    margin-top: 62vh;
                    padding-left: 5em;
                    .Welcome {
                        font-weight: 400;
                        line-height: 3em;
                        font-size: 1em;
                    }
                    h1 {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}
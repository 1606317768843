.VideoDroneContainer {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: #919191;
    color: white;
    .VideoDroneText {
        background: radial-gradient(56.56% 100% at 50% 0%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        .DroneTitle {
            font-family: BadScript;
        }
    }
}
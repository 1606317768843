.ImagesSlider {
    border: 1px solid white;

    .swiper-slide {
        overflow: hidden;
        position: relative;

        .BigImages {
            position: absolute;
            object-fit: cover;
        }
    }

    .swiper-button-prev {
        color: rgb(255, 255, 255);
    }

    .swiper-button-next {
        color: rgb(255, 255, 255);
    }
}
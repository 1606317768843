.HomeContainer {
    overflow: hidden;
    .HeroImg {
        background-image: url('../../../Assets/Images/Tropical\ Love\ 1.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }
    .DivHome {
        position: absolute;
        .HomeRow {
            .DivContent {
                text-align: left;
                color: rgb(255, 255, 255);
                .Welcome {
                    font-family: BadScript;
                }
                h1 {
                    font-family: Amalfi;
                }
            }
        }
    }
}
.NavBarContainer {
    .NavBarRow {
        display: flex;
        .NavBarLogo {
            display: flex;
            justify-content: left;
            ul {
                display: flex;
                list-style: none;
                li {
                    font-family: Amalfi;
                }
            }
        }
        .NavBarLinks {
            display: flex;
            justify-content: right;
            ul {
                display: flex;
                list-style: none;
                align-items: center;
                justify-content: space-between;
                li {
                    color: rgb(255, 255, 255);
                    button {
                        border: none;
                        background-color: transparent;
                        text-transform: uppercase;
                        color: rgb(255, 255, 255);
                    }
                }
            }
        }
        .NavBarLink {
            text-decoration: none;
            cursor: pointer;
            text-transform: uppercase;
            color: rgb(255, 255, 255);
        }
        .NavBarLink:hover {
            border-bottom: 1px solid white;
        }
        .ButtonLink:hover {
            border-bottom: 1px solid white;
        }
    }
}